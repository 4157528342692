import React, { Fragment } from "react";

import SelectOrganisation from "../components/SelectOrganisation";

const Home = () => (
  <Fragment>
    <SelectOrganisation />
  </Fragment>
);

export default Home;
