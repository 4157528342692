import React from "react";

import logo from "../assets/logo.jpg";

import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";

// This claim contains a comma-separated list of organisations that the user has access to.
const UsersOrganisationsClaimName = 'https://zeromission.io/organisations';

// This function builds the URL to redirect the user to the selected organisation / environment.
// For example   https://{{organisation}}.zeromission.ai --> https://google.zeromission.ai
const BuildUrl = (organisation) => getConfig().applicationUrl.replace("{{organisation}}", organisation);

const SelectOrganisation = () => {
  const { user, loginWithRedirect } = useAuth0();

  let organisations = [];
  if (user) {
    const as_string = user[UsersOrganisationsClaimName];
    if (as_string) {
      organisations = as_string.split(",");
    }
  } else {
    loginWithRedirect({});
    return;
  }
  
  if (organisations.length === 0) {
    // The user doesn't have access to any organisations
    return (
      <div className="text-center hero my-5">
        <img src={logo} alt="ZeroMission logo"  width="300px" />
        <h1 className="mb-4">No Access</h1>
  
        <p className="lead">
          You do not have access to any organizations. Please contact your system administrator.
        </p>
      </div>
    );
  }

  if (organisations.length === 1) {
    // The user only has access to a single organisation,  so we automatically
    // redirect the user to it.
    window.location.href = BuildUrl(organisations[0]);
    return <></>;
  }


  // The user has access to multiple organisations so they need to pick one.
  return (
    <div className="text-center hero my-5">
      <img src={logo} alt="ZeroMission logo"  width="300px" />
      <h1 className="mb-4">Select Organization</h1>

      <p className="lead">
        <ul>

        {organisations.map((organisation) => (
          <li><a href={BuildUrl(organisation)}>{organisation}</a></li>
        ))}

        </ul>
      </p>
    </div>
  );
}

export default SelectOrganisation;
