import React from "react";

import {
  Navbar,
  Nav,
  NavItem,
  Button
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

import { getConfig } from "../config";

const AppNavBar = () => {
  const {
    isAuthenticated,
    logout,
  } = useAuth0();


  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    });

  const title = getConfig().title;  

  return (
    <div className="nav-container">
      <Navbar color="light" light expand="md">

          {title &&
            <Nav className="justify-content-center" style={{ flex: 1}}>
            <NavItem>
              <div className="text-danger display-4
              ">{title}</div>
            </NavItem>
            </Nav>
          }

          <Nav className="ml-auto" navbar>
            { isAuthenticated &&
            <NavItem>
              <Button
                id="qsLoginBtn"
                color="primary"
                block
                onClick={() => logoutWithRedirect()}
              >
                Log out
              </Button>
            </NavItem> }
          </Nav>
      </Navbar>
    </div>
  );
};

export default AppNavBar;
